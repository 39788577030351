import React from "react";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 1rem;
`;

const BorderImg = styled.img`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

export default function Slacks() {
  return (
    <Container>
      <BorderImg className="mb1" src="slacks.png" alt="Slack's Radio" />
      <div>
        <iframe
          title="Slack's player"
          src="https://app.radiocult.fm/embed/square-player/slacks?theme=midnight&primaryColor=%23984ace&corners=rounded&playerDisplay=event"
          width="100%"
          height="250px"
          scrolling="no"
          frameborder="0"
          seamless
          allowtransparency="true"
          style={{
            backgroundColor: "transparent",
            borderRadius: "0.75rem",
            marginBottom: "2rem",
          }}
        ></iframe>
      </div>

      <div>
        <p className="tac">
          Slack's is our in-house community radio station broadcasting from the
          backroom of the venue.
        </p>

        <p className="tac">
          It started in 2021 and has around 120 monthly residents and guests on
          a spectrum of alternative music and performance.
        </p>

        <p className="tac">
          The radio broadcasts live thursday - saturday but has a 24/7 feed of
          past shows so you can dip in any time!
        </p>
      </div>
    </Container>
  );
}
