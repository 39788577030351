import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import styled from "styled-components";

const Mailchimp = styled(MailchimpSubscribe)`
  div > input {
    padding: 10rem !important;
  }
  color: white !important;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const Container = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  line-height: 1rem;
  flex-basis: 0;
  flex-grow: 1;
`;

const BorderImg = styled.img`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  filter: invert(100%);
  transition: 200ms;
`;

// a basic form
// const CustomForm = ({ status, message, onValidated }) => {
//   let email;
//   const submit = () =>
//     email &&
//     email.value.indexOf("@") > -1 &&
//     onValidated({
//       EMAIL: email.value,
//     });

//   return (
//     <div>
//       {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
//       {status === "error" && (
//         <div dangerouslySetInnerHTML={{ __html: message }} />
//       )}
//       {status === "success" && (
//         <div dangerouslySetInnerHTML={{ __html: message }} />
//       )}
//       {/* <input
//         style={{ marginBottom: "0.5rem" }}
//         ref={(node) => (name = node)}
//         type="text"
//         placeholder="Name"
//         className="tac"
//       />
//       <br /> */}
//       <input
//         style={{ marginBottom: "0.5rem" }}
//         className="tac"
//         ref={(node) => (email = node)}
//         type="email"
//         placeholder="Email"
//       />
//       <br />
//       <button onClick={submit}>Submit</button>
//     </div>
//   );
// };

export default function Contact() {
  const postUrl = `https://gmail.us21.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  return (
    <>
      <Flex className="tac">
        <Container>
          <h2>OPENING TIMES</h2>

          <h3>MON/TUE/WED/THUR/SUN - CHECK EVENTS </h3>
          <h3>FRI - 7pm till 3am </h3>
          <h3>SAT - 2pm till 3am</h3>
        </Container>
        <Container>
          <h2>ADDRESS</h2>
          <a
            href="https://maps.apple.com/?address=81+Blandford+St,+NE1+3PZ&t=m"
            target="_blank"
            rel="noreferrer"
          >
            <h3>THE LUBBER FIEND</h3>
            <h3>81 Blandford St.</h3>
            <h3>Newcastle upon Tyne</h3>
            <h3>NE1 3PZ</h3>
          </a>
        </Container>
      </Flex>
      <Flex className="tac">
        <Container>
          <h2>CONTACT</h2>
          <h3 style={{}}>
            GENERAL:{" "}
            <a
              style={{ lineHeight: "1rem" }}
              href="mailto:info@thelubberfiend.com"
            >
              info@thelubberfiend.com
            </a>
          </h3>
          <h3 style={{ marginTop: "1rem" }}>
            BOOKING:{" "}
            <a
              style={{ lineHeight: "1rem" }}
              href="mailto:booking@thelubberfiend.com"
            >
              booking@thelubberfiend.com
            </a>
          </h3>
        </Container>
        <Container>
          <h2>MAILING LIST</h2>
          <Mailchimp url={postUrl} />
        </Container>
        <BorderImg alt="This is a border" src="border-1.jpg" />
      </Flex>

      <iframe
        title="Google Maps Embed"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9161.212700552856!2d-1.6240719!3d54.9677823!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xadcc226b322b7f5d!2sThe%20Lubber%20Fiend!5e0!3m2!1sen!2suk!4v1654517284293!5m2!1sen!2suk"
        width="100%"
        height="450"
        style={{ border: 0, marginTop: "1rem" }}
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </>
  );
}
