import React from "react";

import styled from "styled-components";

import { Link, useLocation } from "react-router-dom";

const NavBarContainer = styled.nav`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
  height: 5rem;
  margin-top: 0;
  margin-bottom: 0;
  flex-grow: 1;
  flex-shrink: 0;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;

  > * {
    flex-basis: 0;
    flex-grow: 1;
    text-transform: uppercase;
    color: white;
    text-align: center;
    display: flex;
    text-decoration: none;
    &:hover {
      color: white;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`;

const LinkText = styled.span`
  margin: auto;
`;

const LinkContainer = styled(Link)`
  text-decoration: ${(props) => (props.isactive ? "underline" : "none")};
  &:hover {
    text-decoration: underline;
  }
  transition: 0.5s;
`;

export default function Navbar() {
  const location = useLocation();
  return (
    <NavBarContainer>
      <LinkContainer
        to={location.pathname === "/" ? "/" : "/"}
        isactive={location.pathname === "/" ? 1 : 0}
      >
        <LinkText>Events</LinkText>
      </LinkContainer>

      <LinkContainer
        to={location.pathname === "/venue" ? "/" : "/venue"}
        isactive={location.pathname === "/venue" ? 1 : 0}
      >
        <LinkText>Venue</LinkText>
      </LinkContainer>

      <LinkContainer
        to={location.pathname === "/radio" ? "/" : "/radio"}
        isactive={location.pathname === "/radio" ? 1 : 0}
      >
        <LinkText>Radio</LinkText>
      </LinkContainer>

      <LinkContainer
        to={location.pathname === "/support" ? "/" : "/support"}
        isactive={location.pathname === "/support" ? 1 : 0}
      >
        <LinkText>Support</LinkText>
      </LinkContainer>
    </NavBarContainer>
  );
}
