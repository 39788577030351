import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { device } from "../../styles/device";
import { gql, useQuery } from "@apollo/client";
import { EventCardArchive } from "./EventCardArchive";
import Logo from "../Logo/Logo";

const ColumnContainerArchive = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

const CardListContainerArchive = styled.div`
  display: grid;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: space-between;
  align-content: space-between;
  gap: 10px;
`;

const GET_EVENTS_ARCHIVE = gql`
  query GetEvents {
    events {
      title
      date
      id
    }
  }
`;

export default function EventsArchive() {
  const { loading, error, data, refetch } = useQuery(GET_EVENTS_ARCHIVE);
  const [eventsArchive, setEventsArchive] = useState([]);

  useEffect(() => {
    if (data) {
      const today = new Date();
      today.setDate(today.getDate() - 1);
      let previousEvents =
        data?.events?.length > 0 &&
        [...data.events]
          .filter((event) => new Date(event.date) < today) // Filter for past events
          .sort((eventA, eventB) => {
            return new Date(eventB.date) - new Date(eventA.date); // Descending order
          });

      setEventsArchive(previousEvents);
    }
  }, [data]);

  if (error) {
    refetch();
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p className="tac">Our events page is down for maintenance.</p>
        <a
          href="https://thelubberfiend.bigcartel.com/"
          className="tac"
          style={{ width: "100%" }}
        >
          Head over to Big Cartel for listings and tickets.
        </a>
      </div>
    );
  } else if (loading) {
    return (
      <div>
        <Logo />
      </div>
    );
  } else
    return (
      <ColumnContainerArchive>
        <CardListContainerArchive>
          {eventsArchive &&
            eventsArchive.length > 0 &&
            eventsArchive.map((event) => {
              return (
                <EventCardArchive
                  date={event.date}
                  title={event.title}
                  type={event.type}
                  id={event.id}
                  key={event.id}
                />
              );
            })}
        </CardListContainerArchive>
      </ColumnContainerArchive>
    );
}
