import styled from "styled-components";
import { device } from "./device";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";

export const Grid = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: black;

  grid-template-columns: minmax(10px, 1fr) minmax(300px, 900px) minmax(
      10px,
      1fr
    );
  grid-template-rows: minmax(10px, 1fr) minmax(2px, 1200px) minmax(10px, 1fr);
  // background-color: #FFDBDB;
`;

export const MainContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 150px 80px 1fr 80px;
  grid-column: 1/4;
  grid-row: 1/4;
  @media only screen and ${device.tablet} {
    grid-column: 2/3;
    grid-row: 2/3;

    border-bottom: 1px solid lightgray;
  }
  overflow: hidden;
  flex-direction: column;
  height: 100%;
`;

export const Main = styled.main`
  margin-top: auto;
  /* margin-bottom: auto; */
  height: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: auto;
`;

export const ContentContainer = styled.div`
  display: block;
  overflow-y: auto;
  margin: auto;
  padding: 1rem;
  height: 100%;
`;

export const Image = styled.img`
  width: 100%;
  height: 97%;
  object-fit: cover;
  /* height: 30rem; */
  margin-bottom: 2rem;
`;

export const CoverImage = styled.img`
  display: block;
  /* &:hover {
    cursor: pointer;
  } */
  opacity: ${(props) => (props.isImageLoading ? 0 : 1)};
  position: ${(props) => (props.fullscreen ? "fixed" : "inherit")};
  /* height: 100%; */
  /* object-fit: contain; */
  margin: auto;
  /* max-width: 100%; */
  /* max-height: 100%; */
  bottom: 0;
  left: 0;
  /* margin: auto; */
  overflow: auto;
  right: 0;
  top: 0;
`;

export const CoverVideo = styled.video`
  display: block;
  height: auto;
  width: 100%;
  object-fit: cover;
  margin: auto;
`;

export const PlayPauseButton = styled.button`
  @media ${device.tablet} {
    display: initial;
  }
  background-color: ${() => {
    let currentLocation = useLocation();
    return currentLocation.pathname === "/" || ""
      ? "rgba(245, 245, 245, 0.8)"
      : "rgba(245, 245, 245, 1)";
  }};
  display: none;
  position: fixed;
  z-index: 99;
  height: 5rem;
  width: 5rem;
  right: 1rem;
  bottom: 1rem;
  color: black;
  background-color: transparent;
  border: none;
  border-radius: 20%;
  animation: ${(props) =>
    props.isPlaying &&
    !props.buttonToggleInteration &&
    "blinker 0.75s linear 2"};
  opacity: ${() => {
    let currentLocation = useLocation();
    return currentLocation.pathname === "/venue" ? 0 : 1;
  }};
  @keyframes blinker {
    50% {
      background-color: yellow;
      height: 5.5rem;
      width: 5.5rem;
      right: 0.75rem;
      bottom: 0.75rem;
    }
  }
`;

export const PlayPauseIcon = styled(FontAwesomeIcon)`
  font-size: 4rem;
  margin: auto;
  color: black;
`;
