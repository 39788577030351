import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  /* display: flex; */
  height: 200px;
  /* background-color: grey; */
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

const OuterLogo = styled.img`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: 120px;
  /* top: 10%; */
  animation-name: spin;
  animation-duration: 30000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 2;
  border-radius: 50%;
  border: 1px solid lightgray;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const InnerLogo = styled.img`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  /* top: 0; */
  height: 120px;
  border-radius: 55%;
`;

export default function Logo() {
  return (
    <Container>
      <OuterLogo src="White-Circle-Logo.png" />
      <InnerLogo src="White-Logo-Center.png" />
    </Container>
  );
}
