import React from "react";

import styled from "styled-components";
import Contact from "./Contact";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 1rem;
`;

const BorderImg = styled.img`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

export default function Venue() {
  return (
    <Container>
      <Contact />
      <BorderImg
        style={{ marginTop: "1rem" }}
        src="horizontal-border-1.png"
        alt="This is a border."
      />
      <p className="tac  mb1">
        The Lubber Fiend is a DIY music and events space in Newcastle upon Tyne
        opened in May 2022. We are one of the cities newest independent
        small-mid sized venues with a current capacity of 140 which we hope to
        extend to 200 in the near future. We are a DIY music space, a bar, a
        radio studio and a workshop. We can be a cinema, a place to host
        markets, record/art fairs, talks and other performances. If you have an
        idea get in touch with us.
      </p>
      <BorderImg
        className="mb1"
        style={{
          filter: "invert(100%)",
          "-webkit-transform": "scaleX(-1)",
          transform: "scaleX(-1)",
        }}
        src="horizontal-border-1.png"
        alt="This is a border."
      />
      <div>
        <h2 className="tac underline">ACCESSIBILITY & EQUAL ACCESS</h2>
        <ul style={{ listStylePosition: "outside" }}>
          <p className="tac">
            We are based on the first floor of 81 Blandford Street, NE1 3PZ.
            Unfortunately we are not accessible to wheelchair and ambulant
            disabled members of the public.
          </p>

          <p className="tac">
            Our toilets are presently gendered but we aim to revert to our
            original plan of gender neutral sit-down and stand up facilities
            very soon. We have an ambulant disabled WC
          </p>

          <p className="tac">
            Events are a mix of seated and standing. Seats can be provided for
            predominantly stand-up events on request.
          </p>

          <p className="tac ">
            We offer free tickets for carers attending with disabled customers -
            please provide a Nimbus access card where possible. We are more than
            happy to reserve a seat or a space for a wheelchair if you need it.
            Please email{" "}
            <a href="mailto:info@thelubberfiend.com">info@thelubberfiend.com</a>{" "}
            or ask at the bar on arrival
          </p>

          <p className="tac ">
            Lights during performances can be dangerous to those with epilepsy
            or a history of seizures. If you suffer from these and would like to
            attend please notify us in advance and we will cease their use.
            However The Lubber Fiend is no way liable for any harm or distress
            this may cause.
          </p>
        </ul>

        <p className="tac mb1">
          If you have any additional access requirements, email{" "}
          <a href="mailto:info@thelubberfiend.com">info@thelubberfiend.com</a>{" "}
          and we’ll be happy to help.
        </p>
      </div>

      <BorderImg src="horizontal-border-1.png" alt="This is a border." />

      <h2 className="tac underline">INCLUSION AND SAFE SPACE POLICY</h2>

      <p className="tac mb1">
        We strive to provide as balanced, safe and comfortable a space as
        possible, so everyone can have fun and be themselves. We welcome all
        people from all backgrounds. We do not tolerate and will take action
        against racism, misogyny, homo/trans/femmephobia, classism and any other
        form of discrimination or prejudice.
      </p>

      <p className="tac mb1">
        Please respect people's space and look after each other. If you feel
        uncomfortable or have any concerns whilst visiting please speak to a
        member of bar staff, security or promotion team, who are all trained in
        our safe space policy. Anyone seen or reported for not respecting this
        policy this will be questioned and/or asked to leave.
      </p>

      <p className="tac mb1">
        Please note some event promoters may also wish to impose their own safe
        space policies over and above ours. These will be advertised in advance
        on social media and at the venue entrance.
      </p>

      <p className="tac mb1">
        We reserve the right to refuse access anyone who we feel may pose a
        threat to others’ enjoyment and comfort. In the interests of maintaining
        a balanced audience we may turn away large single-sex groups. We will
        refuse access to single-sex stag / hen dos (if tickets are bought in
        advance these can be refunded).
      </p>

      <p className="tac mb1">
        Please try not to use your phone on the dancefloor and do not interrupt
        performers when they are playing. Assume DJs do not take requests,
        unless advertised!
      </p>
    </Container>
  );
}
