import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useQuery, gql } from "@apollo/client";
import { EventCard } from "./EventCard";
import EventsModal from "./EventsModal";
import { device } from "../../styles/device";
import Logo from "../Logo/Logo";
import EventsArchive from "./EventsArchive";
import { Button } from "reactstrap";

// Styled component for the scrollable container
const ScrollableContainer = styled.div`
  max-height: 1200px; // Set a max height to enable scrolling
  overflow-y: auto; // Enable vertical scrolling
  margin-bottom: 1rem; // Add margin to separate it from pagination controls
`;

const ColumnContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

const CardListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: space-between;
  align-content: space-between;
  gap: 10px;
`;

const GET_EVENTS = gql`
  query GetEvents {
    events {
      title
      date
      id
      image {
        url
      }
    }
  }
`;

export default function Events({ size }) {
  const { loading, error, data, refetch } = useQuery(GET_EVENTS);
  const columnContainerRef = useRef(null); // Create a ref for the scrollable container

  const [events, setEvents] = useState([]);
  const [isOpen, toggleIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 8; // Change this value to set the number of events per page

  const [showArchive, setShowingArchive] = useState(false);

  useEffect(() => {
    if (selectedId !== "") {
      toggleIsOpen(true);
    }
  }, [selectedId]);

  useEffect(() => {
    if (data) {
      const today = new Date();
      today.setDate(today.getDate() - 1);
      let futureEvents =
        data &&
        data.events &&
        data.events.length > 0 &&
        data.events
          .filter((event) => {
            const eventDate = new Date(event.date);
            return today < eventDate;
          })
          .sort((eventA, eventB) => {
            return new Date(eventA.date) - new Date(eventB.date);
          });

      setEvents(futureEvents);
    }
  }, [data]);

  // Paginate future events
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);

  const totalPages = Math.ceil(events.length / eventsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      columnContainerRef.current.scrollTo({ top: 0, behavior: "smooth" }); // Scroll the container to the top
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      columnContainerRef.current.scrollTo({ top: 0, behavior: "smooth" }); // Scroll the container to the top
    }
  };

  if (error) {
    refetch();
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p className="tac">Our events page is down for maintenance.</p>
        <a
          href="https://thelubberfiend.bigcartel.com/"
          className="tac"
          style={{ width: "100%" }}
        >
          Head over to Big Cartel for listings and tickets.
        </a>
      </div>
    );
  } else if (loading) {
    return (
      <div>
        <Logo />
      </div>
    );
  } else if (showArchive) {
    return (
      <>
        <div style={{ width: "100%", display: "flex" }}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setShowingArchive(false);
            }}
            style={{ margin: "auto", marginBottom: "1rem" }}
          >
            View Upcoming Events
          </Button>
        </div>
        <EventsArchive />
      </>
    );
  } else
    return (
      <>
        <ColumnContainer>
          <ScrollableContainer ref={columnContainerRef}>
            <CardListContainer>
              {currentEvents &&
                currentEvents.length > 0 &&
                currentEvents.map((event) => {
                  return (
                    <EventCard
                      date={event.date}
                      title={event.title}
                      type={event.type}
                      setSelectedId={setSelectedId}
                      id={event.id}
                      key={event.id}
                      toggleIsOpen={toggleIsOpen}
                      image={event.image}
                    />
                  );
                })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "1rem",
                }}
              >
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handlePrevPage} // Retaining the onClick for the Previous button
                  disabled={currentPage === 1} // Disable if on the first page
                  style={{ width: "120px", height: "40px" }}
                >
                  Previous
                </button>
                <div
                  className="tac"
                  style={{
                    width: "120px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleNextPage} // Retaining the onClick for the Next button
                  disabled={currentPage === totalPages} // Disable if on the last page
                  style={{ width: "120px", height: "40px" }}
                >
                  Next
                </button>
              </div>
            </CardListContainer>
            <div style={{ width: "100%", display: "flex" }}>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setShowingArchive(true);
                }}
                style={{ margin: "auto", marginBottom: "1rem" }}
              >
                View Events Archive
              </Button>
            </div>
          </ScrollableContainer>

          <EventsModal
            isOpen={isOpen}
            toggleIsOpen={toggleIsOpen}
            selectedId={selectedId}
            size={size}
          />
        </ColumnContainer>
      </>
    );
}
