import React from "react";
import styled from "styled-components";

import dayjs from "dayjs";

const OuterContainer = styled.div`
  margin: 0.5rem;
  margin-bottom: auto;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  &:hover {
    // cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
  }
  //   transition: 200ms;
  //   border-radius: 3px;
  border-bottom: 1px solid lightgray;
`;

const CardText = styled.div`
  margin: 0.75rem;
  color: rgba(245, 245, 245, 1);
`;

export const EventCardArchive = ({
  title,
  date,
  id,
  setSelectedId,
  type,
  toggleIsOpen,
  image,
}) => {
  return (
    <OuterContainer>
      <CardText>
        <span className="tar" style={{ color: "rgba(245, 245, 245, 1)" }}>
          {dayjs(date).format("D MMMM YYYY")} -{" "}
          <span style={{ color: "rgba(245, 245, 245, 1)" }}>{title}</span>
        </span>
      </CardText>
    </OuterContainer>
  );
};
