import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap";
import { DocumentRenderer } from "@keystone-6/document-renderer";

import { useQuery, gql } from "@apollo/client";
import styled from "styled-components";

import dayjs from "dayjs";
import Logo from "../Logo/Logo";

const OuterModal = styled(Modal)`
  height: 100%;
`;

const Body = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /* min-height: 600px; */
  background-color: black;
`;

const EmbedContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  height: 100%;
`;

const ResponsiveIframe = styled.iframe`
  position: relative;
  height: 100%;
  padding: 1rem;
  margin: auto;
`;

const GET_EVENT = gql`
  query GetEvent($id: ID) {
    event(where: { id: $id }) {
      id
      title
      date
      skiddleID
      ResidentAdvisorEventID
      image {
        url
      }
      ticketLink
      description {
        document
      }
    }
  }
`;

const Image = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  max-width: 100%;
  object-fit: contain;
  &:hover {
    cursor: pointer;
  }
  opacity: ${(props) => (props.imageLoading ? 0 : 1)};
  transition: 1s;
`;

export default function EventsModal({
  selectedId,
  toggleIsOpen,
  isOpen,
  size,
}) {
  const { loading, error, data } = useQuery(GET_EVENT, {
    variables: { id: selectedId },
  });

  const [isEmbedLoading, setIsEmbedLoading] = useState(true);
  const [isEmbedError, setIsEmbedError] = useState(false);
  const [imageLoading, setIsImageLoading] = useState(true);

  useEffect(() => {
    if (data?.event?.skiddleID) {
      var po = document.createElement("script");
      po.type = "text/javascript";
      po.async = true;
      po.src = `https://www.skiddle.com/infofeed/ticketpage.php?ilid=${data?.event?.skiddleID};type=embedded`;
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(po, s);
    }
  }, [data]);

  return (
    <OuterModal
      toggle={(e) => {
        e.preventDefault();
        toggleIsOpen(!isOpen);
      }}
      isOpen={isOpen}
      centered
      fullscreen={true}
      size="xl"
    >
      {!data?.event?.ResidentAdvisorEventID && (
        <ModalHeader
          style={{
            backgroundColor: "black",
            borderTop: "1px solid darkgrey",
            borderLeft: "1px solid darkgrey",
            borderRight: "1px solid darkgrey",
          }}
          toggle={(e) => {
            e.preventDefault();
            toggleIsOpen(!isOpen);
          }}
        >
          {data?.event?.date && (
            <h3>{dayjs(data.event.date).format("dddd D MMM ~ HH:mm")}</h3>
          )}
          {data?.event?.title && <h3>{data.event.title}</h3>}
        </ModalHeader>
      )}

      {error && <p>An error occured :(</p>}
      <Body
        style={{
          backgroundColor: "black",
          borderTop: "1px solid darkgrey",
          borderLeft: "1px solid darkgrey",
          borderRight: "1px solid darkgrey",
        }}
      >
        {data?.event?.image?.url && size?.width > 769 && (
          <div
            style={{
              maxHeight: "400px",
              display: "flex",
            }}
          >
            <Image
              imageLoading={imageLoading}
              src={data.event.image.url}
              onClick={(e) => {
                if (data?.event?.ticketLink) {
                  window.open(data?.event?.ticketLink);
                }
              }}
              onLoad={(e) => {
                setIsImageLoading(false);
              }}
            ></Image>
          </div>
        )}
        {data?.event?.description?.document && (
          <DocumentRenderer document={data?.event?.description?.document} />
        )}
        {data?.event?.skiddleID && (
          <div id={`ticketbox_ph_${data?.event?.skiddleID}`}>
            <p></p>
          </div>
        )}

        {data?.event?.ResidentAdvisorEventID && isEmbedLoading && <Logo />}

        {data?.event?.ResidentAdvisorEventID && !isEmbedError && (
          <EmbedContainer>
            <ResponsiveIframe
              title="Ra Tickets"
              src={`https://ra.co/widget/event/${data?.event?.ResidentAdvisorEventID}?hideback=true`}
              frameBorder="0"
              width="100%"
              onLoad={() => {
                setIsEmbedLoading(false);
              }}
              onError={() => {
                setIsEmbedError(true);
              }}
            />
          </EmbedContainer>
        )}
      </Body>

      {!loading && (
        <ModalFooter
          style={{
            backgroundColor: "black",
            border: "1px solid darkgrey",
          }}
        >
          {data?.event?.ticketLink && (
            <Button
              onClick={(e) => {
                window.open(data.event.ticketLink);
              }}
              color={"success"}
              // style={{ backgroundColor: "white", color: "black" }}
            >
              Buy Tickets
            </Button>
          )}

          <Button
            onClick={(e) => {
              e.preventDefault();
              toggleIsOpen(!isOpen);
            }}
          >
            Back
          </Button>
        </ModalFooter>
      )}
    </OuterModal>
  );
}
