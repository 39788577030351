import React from "react";
import { Button } from "reactstrap";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 1rem;
`;

const BorderImg = styled.img`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

export default function Support() {
  return (
    <Container>
      <BorderImg src="horizontal-border-1.png" alt="This is a border." />
      <p className="tac  mb1">
        Regular donations help to keep our doors open and our programme
        eclectic, weird and sometimes even fun.
      </p>
      <p className="tac  mb1">
        We rely on the community of artists, activists and audiences to keep us
        ticking over.
      </p>
      <p className="tac  mb1">
        If you can spare £5, £10 or even more a month, your generosity makes an
        important contribution to this space.
      </p>
      <Button
        className="mb1"
        color="success"
        href="https://www.paypal.com/donate/?hosted_button_id=R6JUA22RTRU8L"
        tag="a"
      >
        DONATE
      </Button>
      <BorderImg
        className="mb1"
        style={{
          filter: "invert(100%)",
          "-webkit-transform": "scaleX(-1)",
          transform: "scaleX(-1)",
        }}
        src="horizontal-border-1.png"
        alt="This is a border."
      />
    </Container>
  );
}
